import { extendTheme, theme as base } from "@chakra-ui/react";

// https://smart-swatch.netlify.app/#00ace6
const makeColors = ({
  dark,
  primary,
  shade,
  darkest,
  lightest,
}: {
  dark: string;
  primary: string;
  shade: string;
  darkest?: string;
  lightest?: string;
}) => {
  return {
    50: lightest ? lightest : shade,
    100: shade,
    200: shade,
    300: primary,
    400: primary,
    500: primary,
    600: dark,
    700: dark,
    800: dark,
    900: darkest ? darkest : dark,
  };
};

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  styles: {
    global: {
      "html, body": {
        color: "#111928",
      },
      td: {
        fontSize: 14, //Override for datatables
      },
    },
  },
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "18px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: "14px",
      },
    },
  },
  colors: {
    red: makeColors({ dark: "#FB0C49", primary: "#FB5169", shade: "#FFD7DC" }),
    orange: makeColors({ dark: "#FB7F61", primary: "#F2A63D", shade: "#FFEACD" }),
    green: makeColors({ dark: "#9DDB3B", primary: "#6DC96E", shade: "#EFFFD4" }),
    blue: makeColors({ darkest: "#111928", dark: "#1F24CD", primary: "#4045F8", shade: "#F3F4FF" }),
    cyan: makeColors({ dark: "#0E7489", primary: "#33C0DD", shade: "#D9F8FF" }),
    gray: makeColors({
      darkest: "#171923",
      dark: "#48484A",
      primary: "#595F6A",
      shade: "#C9CACC",
      lightest: "#F3F3F3",
    }),
  },
  fonts: {
    heading: `Inter, ${base.fonts?.heading}`,
    body: `Inter, ${base.fonts?.body}`,
  },
});

export default theme;
