export enum DeviceType {
  ENVIROQ = "ENVIROQ",
  THERMALQ = "THERMALQ",
  HOTDROP = "HOTDROP",
  AIRWITS = "AIRWITS",
  AIRWITS_IAQ = "AIRWITS_IAQ",
  AIRWITS_CO2 = "AIRWITS_CO2",
  AIRWITS_CO2_PLUS = "AIRWITS_CO2_PLUS",
  AIRWITS_PM = "AIRWITS_PM",
  DETECTIFY = "DETECTIFY",
  ADEUNIS_PULSE_WATER = "ADEUNIS_PULSE_WATER",
  ADEUNIS_PULSE = "ADEUNIS_PULSE",
  FLEXSENSE = "FLEXSENSE",
  VOLTDROP = "VOLTDROP",
  ROOM_RADAR = "ROOM_RADAR",
  ROOM_RADAR_LINE = "ROOM_RADAR_LINE",
  ROOM_RADAR_REGION = "ROOM_RADAR_REGION",
  UNKNOWN = "UNKNOWN",
}

export interface GenericDevice {
  id: string;
  serialNumber: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  getDeviceType: () => string;
  getPhysicalLabel: () => string;
}
