import moment from "moment";
import "moment-timezone";
import { api } from "../api/api";

export enum ReportStatus {
  ERROR = "ERROR",
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  SUBMITTED = "SUBMITTED",
}

export interface ReportResult {
  type: ReportType;
  status: ReportStatus;
  id: string;
  entityName: string;
  entityId: string;
  reportLocation: string;
  hasError: boolean;
  processingAt: string;
  completedAt: string | null;
  updatedAt: string;
  createdAt: string;
  deletedAt: string | null;
}

export type ReportType =
  | "MANAGEMENT"
  | "OCCUPANCY"
  | "COVID_CARE"
  | "PROPERTY"
  | "INSTALLATION_DATA"
  | "INTERVENTION_ENERGY_REPORT"
  | "ENERGY_COMPARISON_REPORT";

export const ReportEntityNames: Record<ReportType, string> = {
  MANAGEMENT: "Organisation",
  OCCUPANCY: "Installation",
  COVID_CARE: "Property",
  PROPERTY: "Property",
  INSTALLATION_DATA: "Installation",
  INTERVENTION_ENERGY_REPORT: "Property",
  ENERGY_COMPARISON_REPORT: "Property",
};

export interface ReportOptions {
  date?: string;
  dateRange?: { startDate: string; endDate: string };
  hideAddresses?: boolean;
  propertyTags?: string[];
}

class ReportService {
  getPropertyReport(entityId: string, reportType: ReportType, options: ReportOptions): Promise<ReportResult> {
    const parameters =
      reportType === "ENERGY_COMPARISON_REPORT"
        ? this.getEnergyReportParams(options)
        : this.getParams(options);
    return new Promise(async (resolve, reject) => {
      try {
        const result: any = await api.post("/v2/report", {
          entityName: ReportEntityNames[reportType],
          entityId,
          type: reportType,
          parameters,
        });

        const reportResult = (await this.pollReportResult(result.id)) as ReportResult;

        return resolve(reportResult);
      } catch (error) {
        reject(error);
      }
    });
  }

  getParams({ date, dateRange, hideAddresses, propertyTags }: ReportOptions) {
    let startTime, endTime: string;

    if (dateRange) {
      startTime = dateRange.startDate;
      endTime = dateRange.endDate;
    } else {
      // report on last month for the full month
      startTime = moment(date, "MMM YYYY").startOf("month").toISOString();
      endTime = moment(date, "MMM YYYY").endOf("month").toISOString();
    }

    return {
      startTime,
      endTime,
      timezone: moment.tz.guess(),
      hideAddresses,
      propertyTags,
    };
  }

  getEnergyReportParams({ date, hideAddresses, propertyTags }: ReportOptions) {
    const prePeriodFromTimestamp = moment(date || "")
      .subtract(1, "month")
      .startOf("month")
      .toISOString();
    const prePeriodToTimestamp = moment(date || "")
      .subtract(1, "month")
      .endOf("month")
      .toISOString();
    const postPeriodFromTimestamp = moment(date || "")
      .startOf("month")
      .toISOString();
    const postPeriodToTimestamp = moment(date || "")
      .endOf("month")
      .toISOString();

    return {
      prePeriodFromTimestamp,
      prePeriodToTimestamp,
      postPeriodFromTimestamp,
      postPeriodToTimestamp,
      timezone: moment.tz.guess(),
      hideAddresses,
      propertyTags,
    };
  }

  getManagementReport(organisationId: string, options: ReportOptions): Promise<ReportResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const result: any = await api.post("/v2/report", {
          entityName: "Organisation",
          entityId: organisationId,
          type: "MANAGEMENT",
          parameters: this.getParams(options),
        });

        const reportResult = (await this.pollReportResult(result.id)) as ReportResult;

        return resolve(reportResult);
      } catch (error) {
        reject(error);
      }
    });
  }

  pollReportResult(reportId: string) {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(async () => {
          try {
            const result: ReportResult = (await api.get(`/v2/report/${reportId}`)) as ReportResult;
            console.log("poll result", result.status);
            if (result.status === ReportStatus.COMPLETED) {
              console.log("COMPLETE");
              return resolve(result);
            }

            if (result.status === ReportStatus.ERROR) {
              console.log("COMPLETE ERROR");
              return reject(result);
            }

            console.log("Not complete poll again");
            // still not complete
            return resolve(this.pollReportResult(reportId));
          } catch (error) {
            return reject(error);
          }
        }, 3000);
      } catch (error) {
        return reject(error);
      }
    });
  }
}

export default new ReportService();
