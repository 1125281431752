import * as React from "react";

const GymIcon = ({ size = 34 }: { size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M31.59 16.818a.876.876 0 0 1-.263-.04l-3.124-.98a2.719 2.719 0 0 1-1.778-3.403l1.36-4.341a2.718 2.718 0 0 1 3.404-1.779l1.372.43a2.719 2.719 0 0 1 1.778 3.403L32.43 16.2a.878.878 0 0 1-.838.617Zm-1.212-8.91a.96.96 0 0 0-.915.672l-1.36 4.34a.958.958 0 0 0 .627 1.2l2.284.717 1.646-5.254a.958.958 0 0 0-.627-1.2l-1.371-.429a.908.908 0 0 0-.284-.045ZM57.037 50.422l-.002-.04c0-.019-.004-.038-.007-.057l-.004-.026a1.112 1.112 0 0 0-.015-.069c-.001-.003-.001-.007-.003-.01L57 50.2c-.001-.004-.001-.008-.003-.011l-4.736-17.252 3.229-13.224a.877.877 0 0 0-.854-1.087 6.766 6.766 0 0 0-6.569 5.116l-.952 3.771H34.728a.878.878 0 0 0-.841.624l-1.379 4.56a.874.874 0 0 0 .136.778.883.883 0 0 0 .706.355h9.988l4.325 15.744H37.331l-2.255-3.203v-6.603c0-1.82-.823-3.502-2.26-4.618l-5.443-4.226a.843.843 0 0 0-.364-.165l-.017-.005c-.012-.003-.025-.004-.037-.006a.878.878 0 0 0-.902.441l-6.765 12.192-11.535 3.583a4.021 4.021 0 0 1 2.61-4.297l6.609-2.394a.878.878 0 0 0 .542-.571l2.992-9.853a.879.879 0 0 0-1.682-.51l-2.865 9.432-6.194 2.245a5.781 5.781 0 0 0-3.468 7.362.878.878 0 0 0 1.089.544l12.76-3.965a.88.88 0 0 0 .51-.413l3.571-6.44 4.24 2.42v9.048H14.768a6.404 6.404 0 0 0-6.396 6.397.88.88 0 0 0 .879.879h46.91a.88.88 0 0 0 .879-.88v-5.517l-.003-.03ZM35.381 29.27h12.42a.88.88 0 0 0 .853-.664l1.119-4.435a5.012 5.012 0 0 1 3.711-3.655l-2.822 11.555H34.534l.847-2.801Zm9.78 4.56h5.52l4.324 15.744h-5.52L45.16 33.83Zm-15.378 5.42-4.702-2.682 2.016-3.632 4.642 3.604a4.06 4.06 0 0 1 1.58 3.23v6.88c0 .182.056.358.16.506l1.703 2.418h-4.955v-9.56a.877.877 0 0 0-.444-.764ZM55.28 55.091H10.212a4.646 4.646 0 0 1 4.555-3.76h34.035l.011.002.015-.001h6.45v3.76h.002Z"
      fill="#121331"
    />
    <path
      d="m46.211 18.632-.227-.716a.872.872 0 0 0-.434-.515.89.89 0 0 0-.67-.058l-8.892 2.814-10.189-3.655a.879.879 0 0 0-.68-.065L15.02 19.633a.881.881 0 0 0-.543.494l-4.85 11.386a.88.88 0 0 0 .467 1.155l.484.204c.53.224 1.084.33 1.63.33 1.631 0 3.198-.946 3.895-2.516l2.343-5.273 2.564-.812-2.14 4.622a.089.089 0 0 0-.008.02l-.018.045c-.006.014-.01.03-.014.045-.002.008-.005.014-.008.02-.001.007-.003.011-.003.017a.666.666 0 0 0-.011.053.596.596 0 0 0-.012.086c-.002.013-.003.025-.003.038l-.001.047v.041l.002.041.005.044c.001.013.004.025.007.038l.009.046.01.038.014.042.014.038.016.04.02.04a.42.42 0 0 0 .043.074c.008.01.013.02.02.03l.03.039.024.03.03.033.028.028c.01.01.02.019.032.028l.035.027.033.024.04.028.031.017c.015.01.031.017.047.025l.015.008.02.007c.017.007.033.015.05.02l.041.014.02.005 7.05 1.993a.878.878 0 0 0 .325.063l.01-.001.01.001a.88.88 0 0 0 .846-.64c.006-.02.01-.043.014-.063l3.089-7.24 4.496 1.782a.875.875 0 0 0 .59.02l7.59-2.4a4.244 4.244 0 0 0 2.763-5.322Zm-28.674 5.225a.878.878 0 0 0-.537.48l-2.504 5.635a2.497 2.497 0 0 1-2.895 1.395l4.335-10.18 7.797-2.467-1.724 3.723-4.472 1.414Zm25.38-1.578-7.293 2.307-6.559-2.6a.88.88 0 0 0-.648 1.635l.72.286-2.838 6.651-5.404-1.526 4.933-10.65 9.846 3.532a.88.88 0 0 0 .563.01l8.333-2.637a2.486 2.486 0 0 1-1.654 2.992Z"
      fill="#111928"
    />
  </svg>
);

export default GymIcon;
