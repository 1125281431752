import { api } from "../api";
import { APIUserSetting } from "./types";

export class UserSettingsService {
  async userSettingOverride(entityId: string): Promise<APIUserSetting[]> {
    return api.get(`v2/usersettingoverride?entityId=${entityId}`);
  }
}

export default new UserSettingsService();
