import { Center, CenterProps } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

export const Loader = ({ size, ...props }: { size?: string } & CenterProps) => {
  return (
    <Center flex="1" {...props}>
      <Spinner size={size} />
    </Center>
  );
};
