import React, { useEffect, useRef } from "react";
import { useAuth } from "../auth/useAuth";
import { userRoleHasPermission } from "../services/PermissionService";

export function useHasPermission(permissionName: string) {
  const { user } = useAuth();

  if (!user) {
    return false;
  }

  return userRoleHasPermission(user.currentOrganisationRoleId, permissionName);
}
