import * as React from "react";
import { FormControl, FormLabel, FormErrorMessage, Input, Button } from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  organisationName: yup.string().required("Organisation name is required"),
  email: yup.string().email("Email must be a valid email").required("Email is required"),
});

export type SignupFormInputs = {
  organisationName: string;
  email: string;
};

interface SignupFormProps {
  onSubmit: (inputs: SignupFormInputs) => Promise<void>;
  isProcessing: boolean;
}

export default function SignupForm({ onSubmit, isProcessing }: SignupFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <form style={{ display: "flex", flexDirection: "column" }}>
      <FormControl isInvalid={!!errors?.organisationName?.message} p="4" isRequired>
        <FormLabel>Organisation Name</FormLabel>
        <Input placeholder="Organisation name" {...register("organisationName")} />
        <FormErrorMessage>{errors?.organisationName?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors?.email?.message} px="4" pb="4" isRequired>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email address" type={"email"} {...register("email")} />
        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
      </FormControl>
      <Button
        onClick={handleSubmit(onSubmit)}
        p="4"
        mx="4"
        mt="6"
        colorScheme="blue"
        variant="solid"
        type="submit"
        disabled={isProcessing || Object.keys(errors).length > 0}
      >
        {Boolean(isProcessing) ? "Creating account..." : "Create Account"}
      </Button>
    </form>
  );
}
