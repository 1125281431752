import * as React from "react";

const AlertSidebarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      d="M3.584 13.333h8.832c.976 0 1.584-.686 1.584-1.553a1.5 1.5 0 0 0-.212-.766l-4.425-7.56a1.572 1.572 0 0 0-2.726 0L2.212 11.02a1.48 1.48 0 0 0-.212.76c0 .867.608 1.553 1.584 1.553Zm.006-.883a.658.658 0 0 1-.66-.67c0-.101.017-.22.074-.338l4.42-7.56a.663.663 0 0 1 .579-.315c.224 0 .442.096.568.316l4.413 7.565c.063.112.092.23.092.332 0 .349-.275.67-.671.67H3.59Zm4.413-2.91c.275 0 .43-.158.436-.45l.086-2.967c.006-.287-.224-.501-.528-.501-.31 0-.528.208-.522.495L7.55 9.09c.005.287.166.45.453.45Zm0 1.829c.327 0 .62-.259.62-.585 0-.333-.287-.586-.62-.586-.339 0-.62.26-.62.585 0 .321.287.586.62.586Z"
      fill="#fff"
    />
  </svg>
);

export default AlertSidebarIcon;
