import { useEffect } from "react";

const HubSpotBox: React.FC = () => {
  useEffect(() => {
    // HubSpot configuration object
    // @ts-expect-error Property 'hsConversationsSettings' does not exist on type 'Window & typeof globalThis'
    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    const script = document.createElement("script");
    script.src = "//js-na1.hs-scripts.com/39645244.js";
    script.id = "hs-script-loader";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div id="hs-chat"></div>
    </div>
  );
};

export default HubSpotBox;
