import * as React from "react";

const TownhouseIcon = ({ size = 34, color }: { size?: number; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M17.231 13.363a.88.88 0 0 0-1.204 0L5.465 23.296l-.03.029-2.099 1.973a.88.88 0 0 0 1.204 1.28l.648-.609v13.538c0 .485.394.88.879.88h11.552a.88.88 0 0 0 0-1.76H6.945V24.315l9.683-9.106 7.03 6.614a.878.878 0 1 0 1.203-1.28l-7.63-7.179ZM59.665 25.298l-2.108-1.983-.022-.021-10.553-9.93a.88.88 0 0 0-1.204 0l-3.921 3.689a.88.88 0 0 0 1.204 1.28l3.319-3.12 9.674 9.102v14.317h-10.51a.88.88 0 0 0 0 1.758h11.389a.88.88 0 0 0 .879-.879V25.97l.65.613a.877.877 0 0 0 1.243-.038.884.884 0 0 0-.04-1.246Z"
      fill={color || "#111928"}
    />
    <path
      d="m48.964 29.436-5.627-5.295v-8.224a.88.88 0 0 0-.88-.879h-4.925a.88.88 0 0 0-.88.88v1.934l-4.463-4.199a.88.88 0 0 0-1.205 0L17.03 26.779l-.038.035-2.79 2.623a.88.88 0 0 0 1.205 1.28l1.333-1.254v18.562c0 .485.394.879.879.879h27.924a.88.88 0 0 0 .879-.88V29.459l1.339 1.258a.88.88 0 0 0 1.204-1.28ZM38.411 16.798h3.167v5.69l-3.167-2.98v-2.71ZM34.69 47.145h-6.208V35.416h6.209v11.729h-.001Zm9.973 0h-8.215V34.537a.88.88 0 0 0-.88-.879h-7.965a.88.88 0 0 0-.88.88v12.607H18.5V27.809l13.087-12.308 13.077 12.303v19.34Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default TownhouseIcon;
