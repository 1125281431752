import { AuthPage } from "../../components/auth/AuthPage";
import { useAuth } from "./useAuth";
import type { FC } from "react";
import { Loader } from "../../components/loading/Loader";
import { useRouter } from "next/router";

export const AuthGuard: FC = ({ children }) => {
  const { user, isLoadingAuth } = useAuth();
  const router = useRouter();

  if (isLoadingAuth) {
    return <Loader height="100vh" />;
  }

  // check if we are running on previous domain.
  if (!user && typeof window !== "undefined" && window.location.hostname.indexOf("tether.co.nz") !== -1) {
    console.log("Redirecting to new domain");
    const currentUrl = new URL(window.location.href.replace("tether.co.nz", "tetherhq.com"));
    currentUrl.searchParams.append("weveMoved", "1");
    window.location.href = currentUrl.toString();

    return null;
  }

  if (!user || (router.pathname && router.pathname.startsWith("/auth/"))) {
    return <AuthPage />;
  }

  return <>{children}</>;
};
