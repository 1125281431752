import * as React from "react";

const HouseSideBarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} fill="none">
    <path
      d="M15.028 5.476 8.56.198a.893.893 0 0 0-1.122 0L.972 5.476a.222.222 0 0 0-.032.312l.28.345a.222.222 0 0 0 .312.033l1.135-.928v6.75a.444.444 0 0 0 .444.445h3.556a.444.444 0 0 0 .444-.444V8.433l1.778.008V12a.444.444 0 0 0 .444.444l3.556-.009a.444.444 0 0 0 .444-.444V5.238l1.135.928a.222.222 0 0 0 .313-.033l.28-.344a.223.223 0 0 0-.033-.313Zm-2.587 6.072h.003l-2.667.008v-3.56a.446.446 0 0 0-.443-.445l-2.666-.007a.444.444 0 0 0-.446.444v3.56H3.556V4.513L8 .886l4.444 3.627-.003 7.035Z"
      fill="#fff"
    />
  </svg>
);

export default HouseSideBarIcon;
