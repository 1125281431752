import * as React from "react";

const UnitIcons = ({ size = 34, color }: { size?: number; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M.661 30.972h.012a.702.702 0 0 0 .45-.16L8.035 25a.562.562 0 0 0 .036-.848.71.71 0 0 0-.935-.033L.295 29.872a.587.587 0 0 0-.295.5c0 .332.296.6.661.6ZM13.95 19.81c.161 0 .323-.053.45-.16l3.368-2.832a.565.565 0 0 0 .19-.592.635.635 0 0 0-.486-.432l-.124-.026c-.352-.077-.706.118-.795.436l-3.053 2.567a.562.562 0 0 0-.036.847.697.697 0 0 0 .486.192ZM3.977 46.956c.342 0 .631-.238.659-.552a.553.553 0 0 0-.092-.356v-15.68c0-.331-.296-.6-.661-.6-.366 0-.661.269-.661.6V46.35c0 .313.265.573.609.598l.094.007.052.002Z"
      fill={color || "#111928"}
    />
    <path
      d="M6.952 24.408c.364 0 .66-.267.66-.598l.013-5.289c.28-.074.48-.31.475-.587-.007-.331-.311-.598-.674-.588l-.473.007c-.36.006-.65.272-.65.599l-.013 5.855c0 .331.295.6.66.601h.002Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M6.981 25.394c.366 0 .661-.268.661-.6v-6.317h3.501v2.237c0 .331.296.6.662.6.365 0 .66-.269.66-.6v-2.836c0-.332-.295-.6-.66-.6H6.98c-.365 0-.661.268-.661.6v6.916c0 .332.297.6.662.6ZM13.135 46.955c.366 0 .662-.269.662-.6V34.122h7.236v12.232c0 .331.296.6.661.6.366 0 .662-.269.662-.6V33.523c0-.332-.296-.6-.662-.6h-8.56c-.365 0-.66.268-.66.6v12.832c0 .33.296.6.661.6Z"
      fill={color || "#4045F8"}
    />
    <path
      d="m31.723 29.005.647-.391s.27-.36 0-.585L17.862 15.936a.71.71 0 0 0-.895.003L.323 29.936a.562.562 0 0 0-.036.847.71.71 0 0 0 .934.033L17.42 17.194 31.291 28.81c.127.105.272.196.432.196Z"
      fill={color || "#111928"}
    />
    <path
      d="M3.878 46.955h27.25c.366 0 .662-.269.662-.6V28.13c0-.331-.296-.6-.661-.6-.366 0-.662.269-.662.6v17.624H4.54V28.67c0-.331-.296-.6-.661-.6-.366 0-.661.269-.661.6v17.685c-.001.331.294.6.66.6ZM60.703 30.972h-.012a.702.702 0 0 1-.45-.16L53.33 25a.562.562 0 0 1-.036-.848.71.71 0 0 1 .934-.033l6.842 5.753a.587.587 0 0 1 .294.5c0 .332-.295.6-.66.6ZM47.415 19.81a.698.698 0 0 1-.45-.16l-3.367-2.832a.565.565 0 0 1-.19-.592.635.635 0 0 1 .486-.432l.123-.026c.352-.077.707.118.796.436l3.052 2.567a.562.562 0 0 1 .036.847.697.697 0 0 1-.486.192ZM57.388 46.956c-.342 0-.632-.238-.66-.552a.553.553 0 0 1 .093-.356v-15.68c0-.331.295-.6.66-.6.366 0 .662.269.662.6V46.35c0 .313-.265.573-.61.598l-.093.007-.052.002Z"
      fill={color || "#111928"}
    />
    <path
      d="M54.413 24.408c-.364 0-.661-.267-.661-.598l-.012-5.289c-.28-.074-.48-.31-.475-.587.007-.331.31-.598.673-.588l.474.007c.36.006.649.272.649.599l.013 5.855c.001.331-.295.6-.66.601h-.001Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M54.383 25.394c-.365 0-.66-.268-.66-.6v-6.317H50.22v2.237c0 .331-.296.6-.661.6-.366 0-.661-.269-.661-.6v-2.836c0-.332.295-.6.66-.6h4.825c.366 0 .661.268.661.6v6.916c0 .332-.296.6-.662.6ZM48.23 46.955c-.366 0-.662-.269-.662-.6V34.122h-7.237v12.232c0 .331-.296.6-.66.6-.366 0-.662-.269-.662-.6V33.523c0-.332.296-.6.661-.6h8.56c.366 0 .661.268.661.6v12.832c0 .33-.297.6-.662.6Z"
      fill={color || "#4045F8"}
    />
    <path
      d="m29.642 29.005-.648-.391s-.269-.36 0-.585l14.508-12.093a.71.71 0 0 1 .896.003l16.644 13.997a.561.561 0 0 1 .036.847.71.71 0 0 1-.935.033L43.946 17.194 30.073 28.81c-.127.105-.272.196-.431.196Z"
      fill={color || "#111928"}
    />
    <path
      d="M57.487 46.955H30.236c-.366 0-.661-.269-.661-.6V28.13c0-.331.296-.6.66-.6.366 0 .662.269.662.6v17.624h25.928V28.67c0-.331.295-.6.66-.6.366 0 .662.269.662.6v17.685c0 .331-.295.6-.66.6Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default UnitIcons;
