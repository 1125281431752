import * as React from "react";
import { FormControl, FormLabel, FormErrorMessage, Input, Button, Box, Text, Link } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserInvitationByToken } from "../../lib/api/auth/types";

const schema = yup.object().shape({
  email: yup.string().email("Email must be a valid email").required("Email is required"),
});

export type ForgotPasswordFormInputs = {
  email: string;
};

interface ForgotPasswordFormProps {
  onSubmit: (credentials: ForgotPasswordFormInputs) => Promise<void>;
  isProcessing: boolean;
  hasError?: boolean;
}

export default function ForgotPasswordForm({ onSubmit, isProcessing, hasError }: ForgotPasswordFormProps) {
  // get email from URL param
  const urlParams = new URLSearchParams(window.location.search);
  const emailFromUrl = urlParams.get("email");

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      email: emailFromUrl ?? "",
    },
  });
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  // This is needed to make input validation work correctly when a password manager is used.
  // Without it you need to trigger a blur event after inputting an email before you can login.
  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("email", e.target.value);

    // If there is an existing error re-check the validation
    if (errors?.email?.message) {
      trigger("email");
    }
  };

  if (submitted && !isProcessing && !hasError) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box display="flex" gap={5} alignItems="center">
          <CheckIcon color="green.400" fontSize={70} />
          <Text>An email with instructions has been sent to your email address.</Text>
        </Box>
        <Link
          href={`/auth/login${window.location.search}`}
          color="gray.300"
          fontSize="md"
          textAlign="center"
          display="inline-block"
          mt={5}
        >
          Return to Sign In
        </Link>
      </Box>
    );
  }

  return (
    <form style={{ display: "flex", flexDirection: "column" }}>
      <Text color="gray.300" fontSize="md" textAlign="center">
        Enter your email address below and we&lsquo;ll send you a link to reset your password
      </Text>
      <FormControl isInvalid={!!errors?.email?.message} p="4" isRequired>
        <FormLabel>Email</FormLabel>
        <Input type="email" placeholder="Email" {...register("email")} onChange={handleEmailChanged} />
        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
      </FormControl>
      <Button
        onClick={handleSubmit(async (inputs) => {
          await onSubmit(inputs);
          setSubmitted(true);
        })}
        p="4"
        mx="4"
        mt="6"
        colorScheme="blue"
        variant="solid"
        type="submit"
        disabled={isProcessing || !!errors.email}
      >
        {Boolean(isProcessing) ? "Sending..." : "Send Reset Link"}
      </Button>
      <Box w="100%" justifyContent="center" alignItems="center" display="flex" mt={5} gap={1}>
        <Text>Already have an account?</Text>
        <Link
          href={`/auth/login${window.location.search}`}
          color="gray.300"
          fontSize="md"
          textAlign="center"
          display="inline-block"
        >
          Sign In
        </Link>
      </Box>
    </form>
  );
}
