import { Box, HStack, Text, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { BsCaretRightFill } from "react-icons/bs";

interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  active?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
  disabled?: boolean;
  disabledText?: string;
  isCollapsed: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const NavItem = (props: NavItemProps) => {
  const { isCollapsed, active, subtle, icon, children, label, endElement, disabled, onClick } = props;
  return (
    <>
      <Tooltip
        label={
          disabled ? (
            "You do not have permission"
          ) : isCollapsed ? (
            <>
              {label.split(" ").map(
                (line, index) => (
                  <React.Fragment key={index}>
                    {index > 0 ? <br /> : ""}
                    {line}
                  </React.Fragment>
                ),
                ""
              )}
            </>
          ) : undefined
        }
        textAlign="center"
      >
        {isCollapsed ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="full"
            px="3"
            py="2"
            cursor="pointer"
            userSelect="none"
            rounded="md"
            transition="all 0.2s"
            bg={active ? "gray.700" : undefined}
            _hover={disabled ? undefined : { bg: "gray.700" }}
            _active={{ bg: "gray.600" }}
            opacity={disabled ? 0.6 : 1}
            fontSize="lg"
            color={active ? "currentcolor" : "gray.400"}
            onClick={onClick}
          >
            {icon}
          </Box>
        ) : (
          <HStack
            w="full"
            px="3"
            py="2"
            cursor="pointer"
            userSelect="none"
            rounded="md"
            transition="all 0.2s"
            bg={active ? "gray.700" : undefined}
            _hover={disabled ? undefined : { bg: "gray.700" }}
            _active={{ bg: "gray.600" }}
            opacity={disabled ? 0.6 : 1}
            onClick={onClick}
          >
            <Box fontSize="lg" color={active ? "currentcolor" : "gray.400"}>
              {icon}
            </Box>
            {label && (
              <Box flex="1" fontSize="lg" fontWeight="inherit" color={subtle ? "gray.400" : undefined}>
                {label}
              </Box>
            )}

            {endElement && !children && <Box>{endElement}</Box>}
            {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
          </HStack>
        )}
      </Tooltip>
    </>
  );
};
