import { Text } from "@chakra-ui/react";
import React from "react";
import { DefaultModal } from "./DefaultModal";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  confirmationText?: string;
  confirmButtonColorScheme?: "blue" | "red";
  isLoading?: boolean;
  confirmText?: string;
}

export const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  confirmationText,
  confirmButtonColorScheme,
  isLoading,
  confirmText,
}: ConfirmationModalProps) => {
  return (
    <DefaultModal
      confirmButtonColorScheme={confirmButtonColorScheme}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title={title}
      isLoading={isLoading}
      confirmText={confirmText}
    >
      {Boolean(confirmationText) && <Text>{confirmationText}</Text>}
    </DefaultModal>
  );
};
