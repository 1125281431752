import React from "react";
import { NavItem } from "./NavItem";
import NextLink from "next/link";
import { Link } from "@chakra-ui/react";

interface SidebarNavItemProps {
  href: string;
  icon: JSX.Element;
  label: string;
  isActive: boolean;
  hasPermission: boolean;
  isExternal?: boolean;
  isCollapsed: boolean;
}

export const SidebarNavItem = ({
  isActive,
  href,
  icon,
  label,
  hasPermission,
  isExternal,
  isCollapsed,
}: SidebarNavItemProps) => {
  if (!hasPermission) {
    return (
      <NavItem
        isCollapsed={isCollapsed}
        disabled={true}
        active={false}
        icon={icon}
        label={label + " (Disabled)"}
      />
    );
  }

  return (
    <NextLink aria-disabled={!hasPermission} href={href} passHref legacyBehavior>
      <Link isExternal={isExternal} _focus={{ outline: "none" }} _hover={{ textDecoration: "none" }}>
        <NavItem isCollapsed={isCollapsed} active={isActive} icon={icon} label={label} />
      </Link>
    </NextLink>
  );
};
