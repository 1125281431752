import * as React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Text,
  Link,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserInvitationByToken } from "../../lib/api/auth/types";
import { useAuth } from "../../lib/auth/useAuth";
import { useRouter } from "next/router";
import { getQueryParamAsString } from "../../lib/utils/routerUtils";
import { Loader } from "../loading/Loader";
import { CheckIcon } from "@chakra-ui/icons";

const schema = yup.object().shape({
  password: yup.string().min(8, "Password must be at least 8 characters").required(),
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

export type ResetPasswordFormInputs = {
  token: string;
  password: string;
  passwordConfirmation: string;
};

interface ForgotPasswordFormProps {
  onSubmit: (credentials: ResetPasswordFormInputs) => Promise<void>;
  isProcessing: boolean;
  invitation?: UserInvitationByToken;
  hasError?: boolean;
}

export default function ForgotPasswordForm({ onSubmit, isProcessing, hasError }: ForgotPasswordFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const { verifyResetPasswordToken } = useAuth();
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [tokenSuccess, setTokenSuccess] = React.useState<boolean>(false);
  const router = useRouter();

  const token = getQueryParamAsString(router, "token");

  React.useEffect(() => {
    if (!token) {
      setTokenError(true);
    } else {
      verifyResetPasswordToken(token)
        .then(() => setTokenSuccess(true))
        .catch((e) => {
          console.log({ e });
          setTokenError(true);
        });
    }
  }, [router.query]);

  if (tokenError || !tokenSuccess) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        {tokenError && (
          <>
            <Box display="flex" gap={5} alignItems="center">
              <Alert status="error">
                <AlertIcon />
                <Box>
                  <Text>Unable to reset password, this token is either invalid or has expired.</Text>
                </Box>
              </Alert>
            </Box>
            <Link
              href={`/auth/login`}
              color="gray.300"
              fontSize="md"
              textAlign="center"
              display="inline-block"
              mt={5}
            >
              Return to Sign In
            </Link>
          </>
        )}
        {!tokenError && <Loader />}
      </Box>
    );
  }

  if (submitted && !isProcessing && !hasError) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box display="flex" gap={5} alignItems="center">
          <CheckIcon color="green.400" fontSize={70} />
          <Text>Password successfully reset!</Text>
        </Box>
        <Link
          href={`/auth/login`}
          color="gray.300"
          fontSize="md"
          textAlign="center"
          display="inline-block"
          mt={5}
        >
          Sign In
        </Link>
      </Box>
    );
  }

  return (
    <form style={{ display: "flex", flexDirection: "column" }}>
      <Text color="gray.300" fontSize="md" textAlign="center" pb={5}>
        Choose a new password for your account
      </Text>
      <ul style={{ fontSize: 13, paddingBottom: 15 }}>
        <li>Password must be at least 8 characters in length</li>
        <li>Password requires uppercase letters, lowercase letters, and either numbers or symbols</li>
      </ul>

      <FormControl isInvalid={!!errors?.password?.message} px="4" pb="4" isRequired>
        <FormLabel>New Password</FormLabel>
        <Input placeholder="Password" type={"password"} {...register("password")} />
        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.passwordConfirmation?.message} px="4" pb="4" isRequired>
        <FormLabel>Confirm Password</FormLabel>
        <Input placeholder="Re-enter your Password" type={"password"} {...register("passwordConfirmation")} />
        <FormErrorMessage>{errors?.passwordConfirmation?.message}</FormErrorMessage>
      </FormControl>
      <Button
        onClick={handleSubmit(async (inputs) => {
          await onSubmit({ ...inputs, token: token || "" });
          setSubmitted(true);
        })}
        p="4"
        mx="4"
        mt="6"
        colorScheme="blue"
        variant="solid"
        type="submit"
        disabled={isProcessing || !!errors.password || !!errors.passwordConfirmation}
      >
        {Boolean(isProcessing) ? "Resetting Password..." : "Reset Password"}
      </Button>
      <Box w="100%" justifyContent="center" alignItems="center" display="flex" mt={5} gap={1}>
        <Text>Already have an account?</Text>
        <Link href={`/auth/login`} color="gray.300" fontSize="md" textAlign="center" display="inline-block">
          Sign In
        </Link>
      </Box>
    </form>
  );
}
