/**
 *
 * @export
 * @interface LoginBody
 */
interface LoginBody {
  /**
   * [description]
   * @type {string}
   * @memberof LoginBody
   */
  username: string;
  /**
   * [description]
   * @type {string}
   * @memberof LoginBody
   */
  password: string;
}

export class NotFoundError extends Error {
  private is404: boolean;
  constructor(...args: any[]) {
    super(...args);
    this.is404 = true;
  }
}

export interface RefreshResponse {
  token: string;
  refreshToken: string;
  accessTokenExpiry: number;
  scope: string;
}

export interface APIError {
  message: string;
  statusCode: number;
}

export const baseURL = process.env.NEXT_PUBLIC_API_HOST || "https://api.staging.tetherhq.com";

export const appClientDetails = {
  client_id: process.env.NEXT_PUBLIC_CLIENT_ID || "695faef0-9c43-4df1-b561-e22815931163",
  client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET || "b736a0d5-2127-4238-9deb-6e570f395b52",
};
