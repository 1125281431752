import * as React from "react";

const HouseIcon = ({ size = 34, color }: { size?: number; color?: string }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M55.576 30.9h-.017a.949.949 0 0 1-.643-.253l-9.896-9.174a.947.947 0 0 1 1.286-1.39l9.795 9.08a.946.946 0 0 1-.525 1.736ZM36.553 13.28a.944.944 0 0 1-.643-.252l-4.822-4.47a.948.948 0 0 1 .424-1.616l.177-.042a.948.948 0 0 1 1.138.688l4.37 4.051a.947.947 0 0 1-.644 1.641ZM50.828 56.126a.948.948 0 0 1-.812-1.435V29.945a.946.946 0 1 1 1.893 0V55.17c0 .494-.38.904-.872.944l-.134.01-.075.003Z"
      fill={color || "#111928"}
    />
    <path
      d="M46.572 20.54a.946.946 0 0 1-.947-.945l-.017-8.347a.947.947 0 0 1 .284-1.855l.678.012c.516.008.93.429.93.945l.018 9.24a.947.947 0 0 1-.945.95h-.001Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M46.526 22.095a.946.946 0 0 1-.946-.946v-9.97h-5.012v3.53a.946.946 0 1 1-1.893 0v-4.477c0-.523.423-.946.946-.946h6.907c.523 0 .946.423.946.946V21.15a.947.947 0 0 1-.948.946ZM37.718 56.123a.946.946 0 0 1-.947-.947V35.87h-10.36v19.305a.946.946 0 1 1-1.892 0V34.924c0-.523.423-.947.946-.947H37.72c.523 0 .947.424.947.947v20.252a.95.95 0 0 1-.948.947Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M7.424 30.9a.945.945 0 0 1-.639-1.646l24.167-22.09a.945.945 0 0 1 1.282.005l23.827 22.09a.947.947 0 0 1-1.286 1.388L31.587 9.15 8.063 30.652a.945.945 0 0 1-.64.247Z"
      fill={color || "#111928"}
    />
    <path
      d="M50.971 56.123H11.96a.946.946 0 0 1-.947-.947V26.413a.946.946 0 1 1 1.893 0v27.815h37.117V27.265a.946.946 0 1 1 1.893 0v27.91a.945.945 0 0 1-.944.948Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default HouseIcon;
