import * as React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Link,
  Box,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserInvitationByToken } from "../../lib/api/auth/types";

const schema = yup.object().shape({
  username: yup.string().email("Email must be a valid email").required("Email is required"),
  password: yup.string().min(4, "Password must be at least 4 characters").required(),
});

export type LoginFormInputs = {
  username: string;
  password: string;
};

interface LoginFormProps {
  onSubmit: (credentials: LoginFormInputs) => void;
  isProcessing: boolean;
  invitation?: UserInvitationByToken;
}

export default function LoginForm({ onSubmit, isProcessing, invitation }: LoginFormProps) {
  const urlParams = new URLSearchParams(window.location.search);
  const emailFromUrl = urlParams.get("email");

  const showWeveMoved = urlParams.get("weveMoved") && window.location.hostname.indexOf("tetherhq.com") !== -1;

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      username: emailFromUrl ?? "",
    },
  });

  // This is needed to make input validation work correctly when a password manager is used.
  // Without it you need to trigger a blur event after inputting an email before you can login.
  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("username", e.target.value);

    // If there is an existing error re-check the validation
    if (errors?.username?.message) {
      trigger("username");
    }
  };

  console.log({ showWeveMoved });
  return (
    <>
      {showWeveMoved && (
        <Alert>
          <AlertIcon />
          We have moved domains to portal.tetherhq.com <br />
          Please use this going forward.
        </Alert>
      )}

      <form style={{ display: "flex", flexDirection: "column" }}>
        <FormControl isInvalid={!!errors?.username?.message} p="4" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Email"
            defaultValue={invitation ? invitation.email : ""}
            {...register("username")}
            onChange={handleEmailChanged}
          />
          <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.password?.message} px="4" pb="4" isRequired>
          <FormLabel>Password</FormLabel>
          <Input placeholder="Password" type={"password"} {...register("password")} />
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <Button
          onClick={handleSubmit(onSubmit)}
          p="4"
          mx="4"
          mt="6"
          alignSelf="stretch"
          colorScheme="blue"
          variant="solid"
          type="submit"
          disabled={isProcessing || !!errors.username || !!errors.password}
        >
          {Boolean(isProcessing) ? "Logging in..." : "Login"}
        </Button>
        <Box w="100%" justifyContent="center" display="flex">
          <Link
            href={`/auth/request-password${window.location.search}`}
            color="gray.300"
            fontSize="md"
            textAlign="center"
            display="inline-block"
            mt={5}
          >
            Forgotten password?
          </Link>
        </Box>
      </form>
    </>
  );
}
