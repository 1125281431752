import { Alert, AlertIcon } from "@chakra-ui/alert";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { EmailIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Heading, Text, Link } from "@chakra-ui/layout";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { CreateAccountRequest } from "../../lib/auth/AuthProvider";
import { useAuth } from "../../lib/auth/useAuth";
import { getQueryParamAsString } from "../../lib/utils/routerUtils";
import { Loader } from "../loading/Loader";
import { TetherLogo } from "../logo/TetherLogo";
import LoginForm, { LoginFormInputs } from "./LoginForm";
import AcceptInvitePanel from "./signup/AcceptInvitePanel";
import CreateAccountForm from "./signup/CreateAccountForm";
import SignupForm, { SignupFormInputs } from "./signup/SignupForm";
import { UserInvitationByToken } from "../../lib/api/auth/types";
import { fetchIPFind, useGetCurrentIpLocationDetails } from "../../lib/api/ip-location/hooks";
import ForgotPasswordForm, { ForgotPasswordFormInputs } from "./ForgotPasswordForm";
import ResetPasswordForm, { ResetPasswordFormInputs } from "./ResetPasswordForm";
import { Button } from "@chakra-ui/react";

const Card = (props: BoxProps) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{ base: "4", md: "10" }}
    shadow="base"
    rounded={{ sm: "lg" }}
    {...props}
  />
);

const enum AuthState {
  Login = "Login",
  ForgottenPassword = "Request password",
  ResetPassword = "Reset password",
  Signup = "Sign up",
  InviteSent = "Invite sent",
  AcceptInvite = "Accept invite",
  CreateAccount = "Create account",
}

const getHeading = (authState: AuthState | undefined) => {
  switch (authState) {
    case AuthState.Login:
      return "Sign In";
    case AuthState.ForgottenPassword:
      return "Forgotten Password";
    case AuthState.ResetPassword:
      return "Reset Password";
    case AuthState.Signup:
      return "Sign Up";
    case AuthState.InviteSent:
      return "Success!";
    case AuthState.CreateAccount:
      return "Let’s setup your profile";
    default:
      return "";
  }
};

const getSubheading = (authState: AuthState | undefined) => {
  switch (authState) {
    case AuthState.Login:
      return (
        <>
          <Text as="span">Don&apos;t have an account?</Text>
          <Link ml={2} href="/auth/signup">
            Create account
          </Link>
        </>
      );
    default:
      return null;
  }
};

const getAuthStateFromPath = (path: string) => {
  switch (path) {
    case "/auth/request-password":
      return AuthState.ForgottenPassword;
    case "/auth/reset-password":
      return AuthState.ResetPassword;
    case "/auth/signup":
      return AuthState.Signup;
    case "/auth/accept-invite":
      return AuthState.AcceptInvite;
    case "/auth/create-account":
      return AuthState.CreateAccount;
    default:
      return AuthState.Login;
  }
};

interface AuthPageProps {}
export const AuthPage = (props: AuthPageProps) => {
  const [error, setError] = useState<boolean | string>(false);
  const [tokenError, setTokenError] = useState<boolean | string>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [lastEmailUsed, setLastEmailUsed] = useState("");
  const [invitation, setInvitation] = useState<UserInvitationByToken | undefined>(undefined);
  const {
    user,
    signIn,
    signUp,
    acceptInvite,
    processInvite,
    createAccountFromInvite,
    requestPasswordReset,
    resetPassword,
  } = useAuth();
  const router = useRouter();
  const [authState, setAuthState] = useState<AuthState>();

  const getInviteToken = async () => {
    try {
      const inviteToken = getQueryParamAsString(router, "token");
      if (!inviteToken) {
        return false;
      }
      setIsLoading(true);
      setTokenError(false);
      const invitation = await processInvite(inviteToken!);
      setInvitation(invitation);
      setIsLoading(false);
    } catch (error) {
      let msg = "Error fetching invitation";
      if (typeof error === "string") {
        msg = error as string;
      }
      if (error instanceof Error) {
        msg = (error as Error).message;
      }
      setTokenError(msg);
      setIsLoading(false);
    }
  };

  // Used in the invitation query to refetch if the user changes (except on create account, which
  // causes an error to flash up once the invite is accepted).
  const userInviteQueryKey = authState !== AuthState.CreateAccount && user && user.email;

  useEffect(() => {
    if (router.isReady) {
      setError(false);

      const authState = getAuthStateFromPath(router.pathname);
      setAuthState(authState);

      if (authState !== AuthState.ResetPassword) {
        getInviteToken();
      }
    }
  }, [router.pathname, router.isReady, userInviteQueryKey]);

  /* Get redirect URL if provided */
  const redirectUrl = getQueryParamAsString(router, "redirectUrl");
  const inviteToken = getQueryParamAsString(router, "token");

  /* Set error if invite token invalid */
  React.useEffect(() => {
    if (tokenError) {
      setError("This invitation doesn't exist or has expired");
    } else {
      setError(false);
    }
  }, [tokenError]);

  /* Set up form submission handlers */
  const handleSubmitPromise = async (promise: Promise<void>) => {
    try {
      setIsProcessing(true);
      setError(false);
      await promise;
    } catch (e) {
      console.log(e as unknown);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (e && (e as any).message) {
        let message = `${authState} failed${(e as Error).message ? `: ${(e as Error).message}` : ""}`;

        if (message.indexOf("Invalid Grant. Bad password or token") !== -1) {
          message = "Invalid email or password";
        }
        setError(message);
        return;
      }

      setError(`${authState} failed`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSignIn = async (credentials: LoginFormInputs) => {
    let redirect = redirectUrl;
    if (!redirect && router.pathname === "/auth/login") {
      redirect = "/";
    }
    setLastEmailUsed(credentials.username);
    await handleSubmitPromise(
      signIn({
        credentials,
        invitation,
        redirectUrl: redirect,
      })
    );
  };
  const handleForgottenPassword = async ({ email }: ForgotPasswordFormInputs) =>
    await handleSubmitPromise(requestPasswordReset(email));
  const handleResetPassword = async ({ token, password }: ResetPasswordFormInputs) =>
    await handleSubmitPromise(resetPassword(token, password));
  const handleSignUp = async (inputs: SignupFormInputs) => {
    const run = async () => {
      let currentIpLocationDetailsCountryCode;
      try {
        const currentIpLocationDetails = await fetchIPFind();
        currentIpLocationDetailsCountryCode = currentIpLocationDetails?.country_code;
      } catch (error) {
        console.log("Error fetching IP location details", error);
      }

      setLastEmailUsed(inputs.email);
      await signUp({
        ...inputs,
        country: currentIpLocationDetailsCountryCode,
      });
      setAuthState(AuthState.InviteSent);
    };
    await handleSubmitPromise(run());
  };
  const handleAcceptInvite = async () =>
    await handleSubmitPromise(
      (() => {
        if (!invitation || !inviteToken) {
          throw Error("This invitation doesn't exist or has expired");
        }
        return acceptInvite(invitation, inviteToken);
      })()
    );
  const handleCreateAccountFromInvite = async (fields: CreateAccountRequest): Promise<void> =>
    await handleSubmitPromise(
      (() => {
        if (!invitation || !inviteToken) {
          throw Error("This invitation doesn't exist or has expired");
        }
        return createAccountFromInvite(fields, invitation, inviteToken);
      })()
    );

  const handleError = (error: Error | false) => setError(error && error.message);

  const existingAccount =
    router.isReady &&
    !isLoading &&
    error &&
    (error as string).indexOf("You already have a Tether account.") !== -1;
  return (
    <>
      <Box bg={useColorModeValue("gray.50", "inherit")} minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box maxW="md" mx="auto">
          <Box display="flex" justifyContent="center" mb={{ base: "10", md: "20" }}>
            <Link href={"/"}>
              <TetherLogo height={52} width={196} fill={useColorModeValue("#111928", "white")} />
            </Link>
          </Box>

          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            {getHeading(authState)}
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            {getSubheading(authState)}
          </Text>

          {existingAccount && (
            <Box mb={4}>
              <Alert status="info">
                <Box>You already have an account. Would you like to reset your password?</Box>
                <Box>
                  <Button
                    onClick={() => {
                      const emailUrl = lastEmailUsed ? `?email=${encodeURIComponent(lastEmailUsed)}` : "";
                      window.location.href = "/auth/login" + emailUrl;
                    }}
                    p="4"
                    mx="4"
                    mt="6"
                    colorScheme="gray"
                    variant="solid"
                  >
                    Login
                  </Button>
                  <Button
                    onClick={() => {
                      const emailUrl = lastEmailUsed ? `?email=${encodeURIComponent(lastEmailUsed)}` : "";
                      window.location.href = "/auth/request-password" + emailUrl;
                    }}
                    p="4"
                    mx="4"
                    mt="6"
                    colorScheme="gray"
                    variant="solid"
                  >
                    Reset Password
                  </Button>
                </Box>
              </Alert>
            </Box>
          )}
          {router.isReady && !isLoading && error && !existingAccount && (
            <Box mb={4}>
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            </Box>
          )}

          <Card>
            {(!router.isReady || isLoading) && <Loader width="100%" height={50} />}
            {router.isReady &&
              !isLoading &&
              (() => {
                switch (authState) {
                  case AuthState.Login:
                    return (
                      <LoginForm
                        onSubmit={handleSignIn}
                        isProcessing={isProcessing}
                        invitation={invitation}
                      />
                    );
                  case AuthState.ForgottenPassword:
                    return (
                      <ForgotPasswordForm
                        onSubmit={handleForgottenPassword}
                        isProcessing={isProcessing}
                        hasError={Boolean(error)}
                      />
                    );
                  case AuthState.ResetPassword:
                    return (
                      <ResetPasswordForm
                        onSubmit={handleResetPassword}
                        isProcessing={isProcessing}
                        hasError={Boolean(error)}
                      />
                    );
                  case AuthState.Signup:
                    return <SignupForm onSubmit={handleSignUp} isProcessing={isProcessing} />;
                  case AuthState.InviteSent:
                    return (
                      <Text sx={{ textAlign: "center" }}>
                        <EmailIcon color="green.400" fontSize={150} />
                        <br />
                        Check your email to complete the signup.
                      </Text>
                    );
                  case AuthState.AcceptInvite:
                    return (
                      <AcceptInvitePanel
                        onSubmit={handleAcceptInvite}
                        onError={handleError}
                        invitation={invitation}
                        isProcessing={isProcessing}
                        hasError={Boolean(error)}
                      />
                    );
                  case AuthState.CreateAccount:
                    return (
                      <CreateAccountForm
                        onSubmit={handleCreateAccountFromInvite}
                        onError={handleError}
                        invitation={invitation}
                        isProcessing={isProcessing}
                      />
                    );
                  default:
                    return null;
                }
              })()}
          </Card>
        </Box>
      </Box>
    </>
  );
};
