import * as React from "react";

const TetherSideBarIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      d="m13.825 11.03.06-2.781V6.836l1.183-1.551-.06 2.99-.072 3.326a1.248 1.248 0 0 1-.585.973l-2.892 1.59-2.918 1.604a1.262 1.262 0 0 1-1.137 0L4.558 14.04l-2.846-1.722a1.249 1.249 0 0 1-.546-1l.066-3.325.072-3.326a1.262 1.262 0 0 1 .585-.973L4.808 2.09 7.726.486a1.262 1.262 0 0 1 1.137 0l2.846 1.722 2.846 1.723.099.065-.815 1.065c-.033.04-.06.086-.099.125l-4.726 6.027c-.103.16-.249.287-.42.368a.96.96 0 0 1-1.032-.124c-.033 0-1.696-1.098-2.63-1.742l.046-2.413c.08 0 2.991 1.972 2.991 1.972l4.193-5.33a.224.224 0 0 1 .053-.06l-1.091-.657-2.386-1.446a1.045 1.045 0 0 0-.946 0L5.36 3.096 2.934 4.41a1.052 1.052 0 0 0-.493.809l-.059 2.774-.052 2.773a1.045 1.045 0 0 0 .453.829l2.373 1.439 2.373 1.433a1.045 1.045 0 0 0 .946 0l2.432-1.315 2.425-1.314a1.033 1.033 0 0 0 .493-.809Z"
      fill="#F3F3F3"
    />
  </svg>
);

export default TetherSideBarIcon;
