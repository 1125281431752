import { NextRouter } from "next/router";

export const getQueryParamAsString = (router: NextRouter, name: string): string | undefined => {
  const value = router.query[name];
  if (typeof value === "object") {
    if (value.length) {
      return value[0];
    } else {
      return;
    }
  }

  return value;
};
