export const buildQueryParam = (query: object) => {
  return Object.keys(query).reduce(
    (acc: string, curr: string) =>
      String(query[curr as keyof typeof query]) !== "" &&
      String(query[curr as keyof typeof query]) !== "undefined"
        ? `${acc}${acc ? "&" : ""}${curr}=${query[curr as keyof typeof query]}`
        : acc,
    ""
  );
};
