import { Box, Flex, FlexProps, HStack, Img, useMenuButton } from "@chakra-ui/react";
import * as React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { UserIcon } from "../user-icon/UserIcon";

interface AccountSwitcherButtonProps {
  name: string;
  organisationName?: string;
  avatarUrl?: string;
  isCovidCareHeader?: boolean;
  isCollapsed?: boolean;
}

export const AccountSwitcherButton = ({
  isCovidCareHeader,
  name,
  avatarUrl,
  organisationName,
  isCollapsed,
}: AccountSwitcherButtonProps) => {
  const buttonProps = useMenuButton();
  const fontColor = isCovidCareHeader ? "gray.400" : undefined;
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      justifyContent={isCollapsed ? "center" : undefined}
      rounded="lg"
      bg={isCovidCareHeader ? "white" : undefined}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: isCovidCareHeader ? "white.600" : "gray.600" }}
      _focus={{ shadow: "outline" }}
    >
      <HStack flex="1" spacing="3">
        <UserIcon name={name} avatarUrl={avatarUrl} />
        {!isCovidCareHeader && !isCollapsed && (
          <Box textAlign="start">
            <Box
              isTruncated
              fontWeight="semibold"
              style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "20ch" }}
            >
              {name}
            </Box>
            <Box fontSize="xs" color={fontColor}>
              {organisationName}
            </Box>
          </Box>
        )}
      </HStack>
      <Box fontSize="lg" color={fontColor}>
        <ChevronDownIcon />
      </Box>
    </Flex>
  );
};
