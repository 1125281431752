import * as React from "react";
import { Button, Text, Box, Link } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { Loader } from "../../loading/Loader";
import { useAuth } from "../../../lib/auth/useAuth";
import { UserInvitationByToken } from "../../../lib/api/auth/types";

interface AcceptInvitePanelProps {
  onError: (error: Error | false) => void;
  onSubmit: () => Promise<void>;
  isProcessing: boolean;
  invitation?: UserInvitationByToken;
  hasError?: boolean;
}

export default function AcceptInvitePanel({
  onError,
  onSubmit,
  isProcessing,
  invitation,
  hasError,
}: AcceptInvitePanelProps) {
  const router = useRouter();
  const { user } = useAuth();

  React.useEffect(() => {
    if (!invitation) {
      onError(new Error("This invitation doesn't exist or has expired"));
    } else {
      onError(false);
    }
  }, [invitation]);

  return (
    <>
      {((!invitation && !user) || hasError) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            mt: -5,
          }}
        >
          <Link href={`/`} color="gray.300" fontSize="md" textAlign="center" display="inline-block" mt={5}>
            Return to Login
          </Link>
        </Box>
      )}
      {!user && <Loader width="100%" height={50} />}
      {invitation && user && (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Text textAlign="center">
            You have been invited to join <strong>{invitation.organisationName}</strong>
          </Text>
          <Button
            onClick={onSubmit}
            p="4"
            mx="4"
            mt="6"
            colorScheme="blue"
            variant="solid"
            type="submit"
            disabled={isProcessing}
          >
            {Boolean(isProcessing) ? "Accepting invite..." : "Accept Invite"}
          </Button>
        </Box>
      )}
    </>
  );
}
