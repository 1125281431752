import { useGetOrganisationFeatureFlag } from "../../../lib/api/organisations/hooks";
import { useGetProperties } from "../../../lib/api/properties/hooks";
import { UserRoleType } from "../../../lib/api/types/IUserOrganisation";
import { useAuth } from "../../../lib/auth/useAuth";

export default function useHasPortfolioAccess() {
  const { user } = useAuth();

  const {
    value: portfolioDashboardEnabled,
    isLoading: enabledFFLoading,
    error: enabledFFError,
  } = useGetOrganisationFeatureFlag("PORTFOLIO_DASHBOARD");
  const {
    value: portfolioDashboardDisabled,
    isLoading: disabledFFLoading,
    error: disabledFFError,
  } = useGetOrganisationFeatureFlag("DISABLE_PORTFOLIO_DASHBOARD");
  const { data: properties = [], isLoading: propertiesLoading, error: propertiesError } = useGetProperties();

  const isNotFieldUser = Boolean(
    user?.currentOrganisationRoleId &&
      [UserRoleType.Admin, UserRoleType.Owner, UserRoleType.User].includes(
        `${user.currentOrganisationRoleId}` as UserRoleType
      )
  );

  const isLoading = enabledFFLoading || disabledFFLoading || propertiesLoading;

  const isEnabled =
    portfolioDashboardEnabled && !portfolioDashboardDisabled && isNotFieldUser && properties.length > 0;

  return {
    hasPortfolioAccess: isEnabled,
    isLoading,
    error: enabledFFError || disabledFFError || propertiesError,
  };
}
