interface PermissionRole {
  inheritRoles: TetherRole[];
  permissions: string[];
  roleId: number;
}

type TetherRole = "Account Owner" | "Admin" | "User" | "Restricted User";

const permissionsByRole: Record<TetherRole, PermissionRole> = {
  "Account Owner": {
    inheritRoles: ["Admin", "User"],
    permissions: ["CREATE_ORGANISATION"],
    roleId: 2,
  },
  Admin: {
    inheritRoles: ["User"],
    permissions: ["MANAGE_USERS", "MANAGE_DEVICES", "ORGANISATION_SETTINGS"],
    roleId: 3,
  },
  User: {
    inheritRoles: [],
    permissions: [
      "CREATE_PROPERTY",
      "MODIFY_PROPERTY",
      "MODIFY_AREA",
      "ASSIGN_DEVICE",
      "MANAGE_TETHER_TICK",
      "REPORTS",
    ],
    roleId: 4,
  },
  "Restricted User": {
    inheritRoles: [],
    permissions: [],
    roleId: 6,
  },
};

const getExplicitPermissionsForRole = (roleName: TetherRole): string[] => {
  return permissionsByRole[roleName]["permissions"];
};

const getAllPermissionsForRole = (roleName: TetherRole): string[] => {
  const inheritedRoles = permissionsByRole[roleName]["inheritRoles"];
  const rolePermissions = getExplicitPermissionsForRole(roleName);

  const allPermissions = inheritedRoles.reduce((permissionsArray, inheritedRole) => {
    return permissionsArray.concat(getExplicitPermissionsForRole(inheritedRole));
  }, rolePermissions);

  const uniquePermissions: string[] = allPermissions.reduce(function (a: string[], b: string) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);

  return uniquePermissions;
};

const roleNameForRoleId = (roleId: number): TetherRole => {
  const roles = Object.keys(permissionsByRole) as TetherRole[];
  const role = roles.find((r) => {
    return (permissionsByRole[r] as PermissionRole).roleId === roleId;
  });
  if (!role) {
    throw new Error("No role found for role ID: " + roleId);
  }

  return role;
};
const roleHasPermission = (roleName: TetherRole, permissionName: string): boolean => {
  const allPermissions = getAllPermissionsForRole(roleName);
  const hasPermission = allPermissions.indexOf(permissionName) !== -1;
  return hasPermission;
};

export const userRoleHasPermission = (roleId: number, permissionName: string): boolean => {
  if (!roleId) {
    return false;
  }
  const roleName = roleNameForRoleId(roleId);
  return roleHasPermission(roleName, permissionName);
};

export const getEditableRoles = (): { roleId: number; roleName: string }[] => {
  const allRoles: TetherRole[] = Object.keys(permissionsByRole) as TetherRole[];
  return allRoles.map((p) => {
    return {
      roleId: permissionsByRole[p].roleId,
      roleName: p,
    };
  });
};
