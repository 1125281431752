import React, { useState, useEffect, useRef } from 'react';
import { QueryKey, QueryStatus, useQueries, UseQueryOptions, UseQueryResult } from 'react-query';

export type AggregatedQueriesResponse<TData, TError> = {
    data: TData[],
    errors: TError[],
    isError: boolean,
    isIdle: boolean,
    isLoading: boolean,
    isLoadingError: boolean,
    isRefetchError: boolean,
    isSuccess: boolean,
    isFetching: boolean,
    status: QueryStatus,
}

// An extension for useQueries that gives aggregated values for isLoading, isFetching etc
export function useAggregatedQueries<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queries: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[]): AggregatedQueriesResponse<TData, TError> {
    const useQueriesResult = useQueries(queries);

    const isError = !!useQueriesResult.find((r) => r.isError);
    const isIdle = !useQueriesResult.find((r) => !r.isIdle);
    const isLoading = !!useQueriesResult.find((r) => r.isLoading);
    const isSuccess = !useQueriesResult.find((r) => !r.isSuccess);

    let status: QueryStatus = 'idle';
    if (isSuccess) status = 'success';
    if (isError) status = 'error';
    if (isLoading) status = 'loading';

    return {
        data: useQueriesResult.map((r) => r.data as TData),
        errors: useQueriesResult.map((r) => r.error as TError).filter((e) => !!e),
        isError: isError,
        isIdle: isIdle,
        isLoading: isLoading,
        isLoadingError: !!useQueriesResult.find((r) => r.isLoadingError),
        isRefetchError: !!useQueriesResult.find((r) => r.isRefetchError),
        isSuccess: isSuccess,
        isFetching: !!useQueriesResult.find((r) => r.isFetching),
        status,
    }
}