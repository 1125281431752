import * as React from "react";

const SchoolIcon = ({ size = 34, color }: { size?: number; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M32.257 17.247a.923.923 0 0 1-.923-.923V8.798a.923.923 0 1 1 1.846 0v7.526c0 .51-.413.923-.923.923Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M18.455 30.955H8.797a.92.92 0 0 1-.857-1.263l3.91-9.844a.921.921 0 0 1 .858-.582l15.37-.02 3.625-3.576a.923.923 0 0 1 1.3.004l3.593 3.592h15.211c.375 0 .714.227.856.576l3.91 9.638a.923.923 0 0 1-.855 1.27H45.85a.921.921 0 0 1-.651-.27l-9.487-9.486a.919.919 0 0 1-.347-.348l-3.019-3.018-3.233 3.191-.015.015-9.993 9.859a.942.942 0 0 1-.65.262Zm-8.298-1.846h7.92l8.126-8.017-12.87.018-3.176 7.999Zm36.075-.208h8.113l-3.16-7.791H38.44l7.791 7.791Z"
      fill={color || "#111928"}
    />
    <path
      d="M38.169 56.353H26.345a.923.923 0 0 1-.923-.923v-9.734a6.843 6.843 0 0 1 6.835-6.835 6.843 6.843 0 0 1 6.835 6.835v9.734c0 .51-.414.923-.923.923Zm-10.901-1.846h9.977v-8.811a4.993 4.993 0 0 0-4.988-4.99 4.993 4.993 0 0 0-4.989 4.99v8.81Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M32.257 56.353a.923.923 0 0 1-.923-.923V40.187a.923.923 0 1 1 1.846 0V55.43c0 .51-.413.923-.923.923Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M51.806 56.447H12.708a.923.923 0 0 1-.923-.924V30.032a.923.923 0 1 1 1.846 0V54.6h37.253V30.032a.923.923 0 1 1 1.846 0v25.491a.925.925 0 0 1-.924.923Z"
      fill={color || "#111928"}
    />
    <path
      d="M32.257 32.888a4.839 4.839 0 0 1-4.834-4.834 4.84 4.84 0 0 1 4.834-4.833 4.84 4.84 0 0 1 4.833 4.833 4.839 4.839 0 0 1-4.833 4.834Zm0-7.821a2.991 2.991 0 0 0-2.988 2.987 2.991 2.991 0 0 0 2.988 2.988 2.991 2.991 0 0 0 2.987-2.988 2.991 2.991 0 0 0-2.987-2.987ZM22.482 41.684h-5.865a.923.923 0 0 1-.924-.923v-2.932a3.859 3.859 0 0 1 3.855-3.854 3.859 3.859 0 0 1 3.855 3.854v2.932a.92.92 0 0 1-.921.923Zm-4.942-1.846h4.018v-2.009a2.011 2.011 0 0 0-4.02 0v2.009h.002ZM47.897 41.684h-5.865a.923.923 0 0 1-.923-.923v-2.932a3.859 3.859 0 0 1 3.855-3.854 3.859 3.859 0 0 1 3.854 3.854v2.932a.92.92 0 0 1-.921.923Zm-4.942-1.846h4.019v-2.009a2.011 2.011 0 0 0-4.02 0v2.009h.001ZM38.169 13.63h-5.912a.923.923 0 0 1-.923-.923V8.798c0-.51.413-.923.923-.923h5.912a.926.926 0 0 1 .686 1.541l-1.204 1.338 1.203 1.338a.922.922 0 0 1-.686 1.538Zm-4.99-1.846h2.918l-.372-.415a.925.925 0 0 1 0-1.235l.373-.414H33.18v2.064Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M51.67 56.356a.923.923 0 0 1-.924-.922l-.019-25.456a.923.923 0 0 1 1.847-.002l.019 25.457c0 .51-.414.923-.924.923Zm3.809-25.565a.922.922 0 0 1-.863-.596l-3.549-9.368a.923.923 0 0 1 .675-1.552h.074c.384 0 .727.237.863.596l3.663 9.67a.923.923 0 0 1-.863 1.25Zm-19.047-9.708a.924.924 0 0 1-.64-.258l-3.89-3.75a.923.923 0 0 1-.458-.798v-.036a.923.923 0 0 1 1.564-.665l4.066 3.919a.922.922 0 0 1-.642 1.588Z"
      fill={color || "#111928"}
    />
  </svg>
);

export default SchoolIcon;
