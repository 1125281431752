import React, { useState } from "react";
import Konami from "react-konami-code";

export default function AppWrapper({ children }: { children?: React.ReactNode }) {
  // const [isRotated, setIsRotated] = useState(false);

  return <>{children}</>;

  /*return (
    <>
      <div id="flip-wrapper" className={isRotated ? "rotate" : undefined}>
        <div className="flip-card">
          <div className="flip-face flip-face-front">{children}</div>
          <div className="flip-face flip-face-back">
            {isRotated && (
              <iframe
                src="https://bward2.github.io/pacman-js/"
                style={{ width: "100%", height: "100%", backgroundColor: "ffdf00" }}
              />
            )}
          </div>
        </div>
      </div>

      <Konami
        timeout={4000}
        action={() => {
          setIsRotated((curIsRotated) => !curIsRotated);
        }}
      ></Konami>
    </>
  );*/
}
