import { useContext } from "react";
import { useGetOrganisationFeatureFlag } from "../api/organisations/hooks";

import { AuthContext } from "./AuthProvider";

export function useAuth() {
  const auth = useContext(AuthContext);

  return auth;
}

export function useShouldShowTetherTick() {
  const auth = useAuth();
  const inNZ = auth.orgSettings.COUNTRY === "NZ";
  const { value: shouldHideHealthyHomes } = useGetOrganisationFeatureFlag("SHOULD_HIDE_HEALTHY_HOMES", inNZ);
  return {
    shouldShowTetherTick: inNZ && !shouldHideHealthyHomes,
  };
}

export function useIsSupportUser() {
  const auth = useAuth();
  return auth.user?.email === "support@tether.co.nz";
}
