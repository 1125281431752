import * as React from "react";

const MembersSideBarIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      d="M13.6 7.467a2 2 0 1 0 .001-4 2 2 0 0 0-.001 4Zm0-3.2a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Zm-5.6 4c1.547 0 2.8-1.253 2.8-2.8 0-1.548-1.253-2.8-2.8-2.8a2.799 2.799 0 0 0-2.8 2.8c0 1.547 1.252 2.8 2.8 2.8Zm0-4.8c1.102 0 2 .897 2 2 0 1.102-.898 2-2 2-1.103 0-2-.898-2-2 0-1.103.897-2 2-2Zm6.1 4.8h-1c-.38 0-.732.12-1.027.322.234.16.447.348.634.56a.995.995 0 0 1 .393-.082h1c.605 0 1.1.537 1.1 1.2 0 .22.18.4.4.4.22 0 .4-.18.4-.4 0-1.103-.852-2-1.9-2Zm-11.7-.8a2 2 0 1 0 .001-4 2 2 0 0 0-.001 4Zm0-3.2a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Zm7.602 4.5c-.835 0-1.042.3-2.002.3-.96 0-1.168-.3-2.003-.3-.907 0-1.79.405-2.307 1.172-.31.46-.49 1.013-.49 1.608v1.12a1.2 1.2 0 0 0 1.2 1.2h7.2a1.2 1.2 0 0 0 1.2-1.2v-1.12c0-.595-.18-1.148-.49-1.608-.518-.767-1.4-1.172-2.308-1.172Zm1.998 3.9c0 .22-.18.4-.4.4H4.4c-.22 0-.4-.18-.4-.4v-1.12c0-.415.122-.818.353-1.16.345-.513.96-.82 1.642-.82.685 0 .93.3 2.005.3 1.075 0 1.32-.3 2.002-.3.683 0 1.298.307 1.643.82.23.342.353.745.353 1.16v1.12H12ZM3.928 8.589A1.82 1.82 0 0 0 2.9 8.267h-1c-1.047 0-1.9.897-1.9 2 0 .22.18.4.4.4.22 0 .4-.18.4-.4 0-.663.495-1.2 1.1-1.2h1c.138 0 .27.03.393.082.187-.212.402-.4.635-.56Z"
      fill="#F3F3F3"
    />
  </svg>
);

export default MembersSideBarIcon;
