import { AuthUser } from "../auth/AuthProvider";

export const getFullName = (user: AuthUser) => {
  if (user.firstName && user.lastName) {
    const fullName = user.firstName + " " + user.lastName;
    return fullName;
  }
  if (user.email) {
    return user.email;
  }
  return "Unknown User";
};
