import React, { ReactNode, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export interface DefaultModalProps extends Pick<ModalBodyProps, "overflowY"> {
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void; // Specifically for the Cancel button not clicking the overlay, defaults to onClose
  onConfirm?: (() => void) | (() => Promise<void>);
  title: string | ReactNode;
  titleColor?: string;
  children?: ReactNode;
  confirmDisabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmButtonColorScheme?: "blue" | "red";
  isLoading?: boolean;
  hideCancel?: boolean;
  minWidth?: number | string;
  maxHeight?: number | string;
  size?: string;
  hasColoredHeader?: boolean;
  headerColor?: string;
}

export const DefaultModal = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  confirmDisabled,
  confirmText = "Confirm",
  cancelText = "Cancel",
  children,
  title,
  titleColor,
  confirmButtonColorScheme = "blue",
  isLoading,
  hideCancel,
  minWidth,
  maxHeight,
  size,
  hasColoredHeader,
  headerColor,
  overflowY,
}: DefaultModalProps) => {
  const [confirmProcessing, setConfirmProcessing] = useState<boolean>(false);

  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={minWidth} maxHeight={maxHeight}>
        <ModalHeader borderTopRadius={"md"} bg={hasColoredHeader ? headerColor : "white"} color={titleColor}>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY={overflowY || "auto"}>{children}</ModalBody>
        <ModalFooter>
          {Boolean(!hideCancel) && (
            <Button colorScheme="white" variant="outline" mr={3} onClick={onCancel || onClose}>
              {cancelText}
            </Button>
          )}
          {Boolean(onConfirm) && (
            <Button
              isLoading={isLoading || confirmProcessing}
              colorScheme={confirmButtonColorScheme}
              disabled={confirmDisabled}
              onClick={async () => {
                setConfirmProcessing(true);
                if (onConfirm) {
                  await onConfirm();
                }
                setConfirmProcessing(false);
              }}
            >
              {confirmText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
