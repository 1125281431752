import { api } from "../api";

export type OrgSettings = Record<OrgSettingsTypes, string | number>;
interface OrgResponseSetting {
  name: string;
  value: string;
}

export const OrgSettingDefaults: OrgSettings = {
  COST_PER_KWH: 0.294,
  CO2_PER_KWH: 97.73,
  COUNTRY: "NZ",
  CURRENCY: "NZD",
  SUBMETERING_DAY_RATE: 0,
};

export enum OrgSettingsTypes {
  CO2_PER_KWH = "CO2_PER_KWH",
  COST_PER_KWH = "COST_PER_KWH",
  COUNTRY = "COUNTRY",
  CURRENCY = "CURRENCY",
  SUBMETERING_DAY_RATE = "SUBMETERING_DAY_RATE",
}

class OrgSettingsService {
  getAllOrgSettings(): Promise<OrgSettings> {
    return api.get(`v2/organisationSetting`).then((result: OrgResponseSetting[]) => {
      const settings: Record<string, string> = {};
      result.map((setting: OrgResponseSetting) => {
        settings[setting.name] = setting.value;
      });

      return settings as OrgSettings;
    });
  }
}

export default new OrgSettingsService();
