export interface IUserOrganisation {
  createdAt?: string;
  primaryLogin?: boolean;
  updatedAt?: string;
  userId: string;
  organisationId: string;
  userRoleId: number;
}

export enum UserRoleType {
  Owner = "2",
  Admin = "3",
  User = "4",
  RestrictedUser = "6",
}

export const UserRoleNames: Record<UserRoleType, string> = {
  [UserRoleType.Owner]: "Account Owner",
  [UserRoleType.Admin]: "Admin",
  [UserRoleType.User]: "General User",
  [UserRoleType.RestrictedUser]: "Restricted User",
};
export const UserRoleDescriptions: Record<UserRoleType, string> = {
  [UserRoleType.Owner]: "Full access and billing",
  [UserRoleType.Admin]: "Full access except billing",
  [UserRoleType.User]: "Full access except billing and user management",
  [UserRoleType.RestrictedUser]: "Restricted access to specific properties only",
};
