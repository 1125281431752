import { Box } from "@chakra-ui/layout";
import React from "react";
import { PropertyType } from "../../../lib/api/types/PropertyType";
import ApartmentIcon from "./ApartmentIcon";
import CommercialIcons from "./CommercialIcons";
import GymIcon from "./GymIcon";
import HouseIcon from "./HouseIcon";
import SchoolIcon from "./SchoolIcon";
import TownhouseIcon from "./TownhouseIcon";
import UnitIcons from "./UnitIcons";

interface PropertyIconProps {
  type?: PropertyType;
  size?: number;
  color?: string;
}

export const PropertyIcon = ({ type, size, color }: PropertyIconProps) => {
  switch (type) {
    case "house":
      return <HouseIcon size={size} color={color} />;
    case "commercial":
      return <CommercialIcons size={size} color={color} />;
    case "school":
      return <SchoolIcon size={size} color={color} />;
    case "apartment":
      return <ApartmentIcon size={size} color={color} />;
    case "townhouse":
      return <TownhouseIcon size={size} color={color} />;
    case "unit":
      return <UnitIcons size={size!} />;
    case "gym":
      return <GymIcon size={size!} />;

    default:
      return null;
  }
};
