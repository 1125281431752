import { useQuery } from "react-query";
import { api } from "../api";
import { IpFindResponse } from "../types/IpFindResponse";

const ipFindKey = process.env.NEXT_PUBLIC_IP_FIND_API_KEY || "";

/**
 * Read more on [IpFind](https://ipfind.com/docs/#get-the-current-user-or-client-39-s-ip-location)
 */
export const useGetCurrentIpLocationDetails = () => {
  return useQuery<IpFindResponse>(["ipLocation"], () => {
    return api.get("https://api.ipfind.com/me", {
      auth: ipFindKey,
    });
  });
};

export const fetchIPFind = async (): Promise<IpFindResponse> => {
  if (!ipFindKey) {
    throw new Error("IPFind API key not found");
  }
  return api.get("https://api.ipfind.com/me", {
    auth: ipFindKey,
  });
};
