/* eslint-disable class-methods-use-this */
import { DeviceType, GenericDevice } from "./GenericDevice";

export class HotDrop implements GenericDevice {
  id!: string;

  serialNumber!: string;

  devEUI!: string;

  vutilitiId!: string;

  hardwareVersion!: string;

  createdAt!: string;

  updatedAt!: string;

  deletedAt!: string;

  constructor(props: HotDrop) {
    Object.keys(props).forEach((key) => (this[key as keyof HotDrop] = props[key as keyof HotDrop] as any));
  }

  getDeviceType(): string {
    return DeviceType.HOTDROP;
  }

  getPhysicalLabel(): string {
    return this.vutilitiId;
  }
}
