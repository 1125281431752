import * as React from "react";
import { FormControl, FormLabel, FormErrorMessage, Input, Button, Box } from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateAccountRequest } from "../../../lib/auth/AuthProvider";
import { UserInvitationByToken } from "../../../lib/api/auth/types";
import { useRouter } from "next/router";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
      "Password must be at least 8 characters including one uppercase, one lowercase, and one number."
    )
    .required("Password is required"),
  // customerSegment: yup.string().required(),
  // numberOfProperties: yup.string().required(),
  termsAccepted: yup
    .boolean()
    .equals([true], "You must accept the terms and conditions to create an account."),
});

export type CreateAccountInputs = {
  firstName: string;
  lastName: string;
  password: string;
  customerSegment: string;
  numberOfProperties: string;
  termsAccepted: boolean;
};

interface CreateAccountFormProps {
  onSubmit: (fields: CreateAccountRequest) => Promise<void>;
  onError: (error: Error | false) => void;
  isProcessing: boolean;
  invitation?: UserInvitationByToken;
}

export default function CreateAccountForm({
  onSubmit,
  isProcessing,
  onError,
  invitation,
}: CreateAccountFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAccountInputs>({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const router = useRouter();

  React.useEffect(() => {
    if (!invitation) {
      onError(new Error("This invitation doesn't exist or has expired"));
    } else {
      onError(false);
    }
  }, [invitation, onError]);

  return (
    <>
      {!invitation && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => router.push("/")}>Return to Login</Button>
        </Box>
      )}
      {invitation && (
        <form style={{ display: "flex", flexDirection: "column" }}>
          {/* Hidden username field to make password managers use the email */}
          <FormControl height={0} opacity={0}>
            <Input name="username" value={invitation.email} disabled />
          </FormControl>

          <FormControl isInvalid={!!errors?.firstName?.message} p="4" isRequired>
            <FormLabel>Your first name</FormLabel>
            <Input placeholder="E.g. John" {...register("firstName")} />
            <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.lastName?.message} p="4" isRequired>
            <FormLabel>Your last name</FormLabel>
            <Input placeholder="E.g. Smith" {...register("lastName")} />
            <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.password?.message} p="4" isRequired>
            <FormLabel>Create a password</FormLabel>
            <Input type="password" {...register("password")} />
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          </FormControl>
          {/* 
          <FormControl
            isInvalid={!!errors?.customerSegment?.message}
            errortext={errors?.customerSegment?.message}
            p="4"
            isRequired
          >
            <FormLabel>What best describes your company?</FormLabel>
            <Select placeholder="Please select..." {...register("customerSegment")}>
              <option value="Property Management">Property Management</option>
              <option value="Healthy Home Inspections">Healthy Home Inspections</option>
              <option value="Landlord">Landlord</option>
              <option value="Government">Government</option>
              <option value="Other">Other</option>
            </Select>
            <FormErrorMessage>{errors?.customerSegment?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors?.numberOfProperties?.message}
            errortext={errors?.numberOfProperties?.message}
            p="4"
            isRequired
          >
            <FormLabel>Your first name</FormLabel>
            <Select placeholder="Please select..." {...register("numberOfProperties")}>
              <option value="1">1</option>
              <option value="2-10">2-10</option>
              <option value="20-50">20-50</option>
              <option value="50-200">50-200</option>
              <option value="200+">200+</option>
            </Select>
            <FormErrorMessage>{errors?.numberOfProperties?.message}</FormErrorMessage>
          </FormControl> */}

          <FormControl isInvalid={!!errors?.termsAccepted?.message} p="4" isRequired>
            <FormLabel>
              <label>
                <input type="checkbox" {...register("termsAccepted")} /> I accept the&nbsp;
              </label>
              <a
                href="https://portal.tetherhq.com/assets/terms/tether-terms-and-conditions.pdf"
                target="blank"
              >
                terms and conditions
              </a>
            </FormLabel>
            <FormErrorMessage>{errors?.termsAccepted?.message}</FormErrorMessage>
          </FormControl>

          <Button
            onClick={handleSubmit((fields) => onSubmit(fields))}
            p="4"
            mx="4"
            mt="6"
            colorScheme="blue"
            variant="solid"
            type="submit"
            disabled={isProcessing || Object.keys(errors).length > 0}
          >
            {Boolean(isProcessing) ? "Creating account..." : "Create Account"}
          </Button>
        </form>
      )}
    </>
  );
}
