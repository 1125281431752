interface AuthPersistDetails {
  token: string;
  refreshToken: string;
  maxAge: number;
  scope: string;
}

const REFRESH_TOKEN_NAME = "tether.refresh";
const ACCESS_TOKEN_NAME = "tether.access";
const SCOPE_NAME = "tether.scope";
const REFRESH_EXPIRY_NAME = "tether.expiry";

export const persistAuthToken = ({ token, refreshToken, maxAge, scope }: AuthPersistDetails) => {
  if (!token) throw new Error("Error setting empty token cookie");
  if (!refreshToken) throw new Error("Error setting empty refresh token cookie");

  if (typeof localStorage === "undefined") {
    throw new Error("Local storage unavailable in this context");
  }

  localStorage.setItem(`${REFRESH_TOKEN_NAME}`, refreshToken);
  localStorage.setItem(`${ACCESS_TOKEN_NAME}`, token);
  localStorage.setItem(`${SCOPE_NAME}`, scope);
  localStorage.setItem(`${REFRESH_EXPIRY_NAME}`, maxAge.toString());
};

export const getPersistedTokens = (): {
  token: string | null;
  refreshToken: string | null;
  maxAge: number | null;
  scope: string | null;
} => {
  // stop erroring serverside
  if (typeof localStorage === "undefined") {
    return {
      token: null,
      refreshToken: null,
      maxAge: null,
      scope: null,
    };
  }

  const token = localStorage.getItem(`${ACCESS_TOKEN_NAME}`);
  const refreshToken = localStorage.getItem(`${REFRESH_TOKEN_NAME}`);
  const maxAge = localStorage.getItem(`${REFRESH_EXPIRY_NAME}`);
  const scope = localStorage.getItem(`${SCOPE_NAME}`);

  return {
    token: token || null,
    refreshToken: refreshToken || null,
    maxAge: maxAge ? Number(maxAge) : null,
    scope: scope || null,
  };
};

export const deletePersistentTokens = () => {
  if (typeof localStorage === "undefined") {
    throw new Error("Local storage unavailable in this context");
  }
  localStorage.removeItem(`${ACCESS_TOKEN_NAME}`);
  localStorage.removeItem(`${REFRESH_TOKEN_NAME}`);
  localStorage.removeItem(`${REFRESH_EXPIRY_NAME}`);
  localStorage.removeItem(`${SCOPE_NAME}`);
};

// this is a one off migration to move old tokens to the new api type format
// const migrateOldTokens = () => {
//   const token = localStorage.getItem(`${ACCESS_TOKEN_NAME}`);
//   if (!token) return;

//   const refreshToken = localStorage.getItem(`${REFRESH_TOKEN_NAME}`);
//   const maxAge = localStorage.getItem(`${REFRESH_EXPIRY_NAME}`);

//   if (refreshToken) localStorage.setItem(`${REFRESH_TOKEN_NAME}`, refreshToken);
//   if (token) localStorage.setItem(`${ACCESS_TOKEN_NAME}`, token);
//   if (maxAge) localStorage.setItem(`${REFRESH_EXPIRY_NAME}`, maxAge.toString());

//   // delete old tokens
//   localStorage.removeItem(`${ACCESS_TOKEN_NAME}`);
//   localStorage.removeItem(`${REFRESH_TOKEN_NAME}`);
//   localStorage.removeItem(`${REFRESH_EXPIRY_NAME}`);
// };
