import { DeviceType, GenericDevice } from "./GenericDevice";

export class Device implements GenericDevice {
  id!: string;

  type!: string;

  serialNumber!: string;

  sku!: string;

  shortSerial!: string;

  sigfoxId!: string;

  sigfoxPac!: string;

  hardwareVersion!: string;

  firmwareVersion!: string;

  quickCircuitId!: string;

  createdAt!: string;

  updatedAt!: string;

  deletedAt!: string;

  constructor(props: Device) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(props).forEach((key) => (this[key as keyof Device] = props[key as keyof Device] as any));
  }

  getDeviceType(): string {
    return this.type;
  }

  getPhysicalLabel(): string {
    return this.shortSerial;
  }
}
