import * as React from "react";

const CommercialIcons = ({ size = 34, color }: { size?: number; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 63 63">
    <path
      d="M28.743 56.617h-10.12a.923.923 0 0 1-.923-.923V42.418c0-.51.413-.923.923-.923h10.12c.51 0 .923.413.923.923v13.276c0 .51-.413.922-.923.922Zm-9.197-1.846h8.274V43.34h-8.274v11.43Z"
      fill={color || "#111928"}
    />
    <path
      d="M23.701 56.259a.923.923 0 0 1-.923-.923V42.792c0-.51.413-.923.923-.923h.034c.51 0 .923.413.923.923v12.526a.925.925 0 0 1-.526.834.911.911 0 0 1-.43.107Z"
      fill={color || "#111928"}
    />
    <path
      d="M11.413 56.617a.921.921 0 0 1-.923-.924V8.826c0-.51.413-.923.922-.923l24.686-.028h.001a.921.921 0 0 1 .923.923v46.868c0 .51-.413.923-.922.923l-24.687.028c.002 0 0 0 0 0Zm.923-46.87V54.77l22.84-.026V9.72l-22.84.026Z"
      fill={color || "#111928"}
    />
    <path
      d="M19.695 17.357a.923.923 0 0 1-.922-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.414.923-.924.923ZM23.615 17.357a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM27.534 17.357a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM19.695 24.619a.923.923 0 0 1-.922-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.414.924-.924.924ZM23.615 24.619a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.924-.923.924ZM27.534 24.619a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.924-.923.924ZM19.695 31.88a.923.923 0 0 1-.922-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.414.923-.924.923ZM23.615 31.88a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM27.534 31.88a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM19.695 39.142a.923.923 0 0 1-.922-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.414.923-.924.923ZM23.615 39.142a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM27.534 39.142a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM31.453 17.357a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM31.453 24.619a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.924-.923.924ZM31.453 31.88a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM31.453 39.142a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM15.776 17.357a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM15.776 24.619a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.924-.923.924ZM15.776 31.88a.923.923 0 0 1-.923-.924v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923ZM15.776 39.142a.923.923 0 0 1-.923-.923v-3.63a.923.923 0 1 1 1.846 0v3.63c0 .51-.413.923-.923.923Z"
      fill={color || "#4045F8"}
    />
    <path
      d="M51.587 56.549H36.024a.923.923 0 1 1 0-1.846h14.64v-31.51h-14.64a.923.923 0 1 1 0-1.846h15.563c.51 0 .923.413.923.923v33.356c0 .51-.413.923-.923.923Z"
      fill={color || "#111928"}
    />
    <path
      d="M47.986 28.239h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 32.146h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 36.054h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 39.962h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 43.87h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 47.777h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846ZM47.986 51.685h-8.374a.923.923 0 1 1 0-1.846h8.373a.923.923 0 1 1 0 1.846Z"
      fill={color || "#4045F8"}
    />
  </svg>
);

export default CommercialIcons;
