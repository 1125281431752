import { createContext, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../ConfirmationModal";
// import AlertDialog from "src/components/AlertDialog";
// import CustomDialog from "src/components/CustomDialog";

interface DialogOptions {
  title?: string;
  confirmationText?: string;
  confirmText?: string;
  onSubmit?: () => void;
  variant?: "confirm";
}

interface State {
  loading: boolean;
  options?: DialogOptions;
  open: boolean;
}

interface DialogContextValue extends State {
  loading: boolean;
  options?: DialogOptions;
  open: boolean;
  showDialog: (options: DialogOptions) => void;
  dismissDialog: () => void;
}

interface DialogProviderProps {
  children: ReactNode;
}

type ActionType = "SHOW_DIALOG" | "DISMISS_DIALOG" | "SUBMIT_DIALOG" | "LOADING_COMPLETE";
interface Action {
  type: ActionType;
  payload?: {
    options: DialogOptions;
  };
}

const initialState: State = {
  loading: false,
  options: {},
  open: false,
};

const handlers: Record<ActionType, (state: State, action: Action) => State> = {
  SHOW_DIALOG: (state: State, action: Action): State => {
    return {
      ...state,
      options: action.payload?.options,
      open: true,
    };
  },
  DISMISS_DIALOG: (state: State): State => ({
    ...state,
    loading: false,
    options: {},
    open: false,
  }),
  SUBMIT_DIALOG: (state: State): State => ({
    ...state,
    loading: true,
  }),
  LOADING_COMPLETE: (state: State): State => ({
    ...state,
    loading: false,
    options: {},
    open: false,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const DialogContext = createContext<DialogContextValue>({
  ...initialState,
  loading: false,
  options: {},
  open: false,
  showDialog: () => {},
  dismissDialog: () => {},
});

export const DialogProvider: FC<DialogProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { open } = state;
  const { title, confirmationText, confirmText, onSubmit } = state.options || {};

  const showDialog = async (options: DialogOptions): Promise<void> => {
    dispatch({
      type: "SHOW_DIALOG",
      payload: { options },
    });
  };

  const dismissDialog = async (): Promise<void> => {
    dispatch({
      type: "DISMISS_DIALOG",
    });
  };

  const handleSubmit = async () => {
    dispatch({ type: "SUBMIT_DIALOG" });
    if (onSubmit) {
      await Promise.resolve(onSubmit());
    }
    dispatch({ type: "LOADING_COMPLETE" });
  };

  return (
    <DialogContext.Provider
      value={{
        ...state,
        showDialog,
        dismissDialog,
      }}
    >
      {open && (
        <ConfirmationModal
          title={title || ""}
          confirmationText={confirmationText || ""}
          confirmText={confirmText}
          onConfirm={handleSubmit}
          onClose={() => dispatch({ type: "DISMISS_DIALOG" })}
          isOpen={open}
        />
      )}
      {children}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogContext;
