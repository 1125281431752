import React, { useState, useCallback } from "react";
import { createDescendantContext } from "@chakra-ui/descendant";
import { useKeyPressEvent } from "react-use";
import { Flex, FlexProps, Input, List, Portal, StackProps, useMenu, VStack } from "@chakra-ui/react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { PropertySearchMenuItem } from "./PropertySearchMenuItem";
import { useHotkeys } from "react-hotkeys-hook";
import { Property } from "../../lib/api/types/Property";

const backdropVariants: Variants = {
  initial: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  enter: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
};

const commandPaletteVariants: Variants = {
  initial: {
    scale: 0.9,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  enter: {
    scale: 1,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
  exit: {
    scale: 0.9,
    transition: {
      type: "tween",
      duration: 0.15,
      ease: "easeOut",
    },
  },
};

const MotionFlex = motion<FlexProps>(Flex);
const MotionVStack = motion<StackProps>(VStack);

const [
  MenuDescendantsProvider,
  useMenuDescendantsContext,
  useMenuDescendants,
  useMenuDescendant,
] = createDescendantContext<HTMLElement>();

interface KeyboardMenuDemoProps {
  properties: Property[];
  onItemSelected: (item: Property) => void;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const GloablPropertySearchModal = ({
  properties,
  onItemSelected,
  isOpen,
  onClose,
  onOpen,
}: KeyboardMenuDemoProps) => {
  const searchRef = React.useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState("");
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { descendants } = useMenu();

  const close = useCallback(() => {
    onClose();
    setFocusedIndex(0);
    setSearchText("");
  }, [onClose, setFocusedIndex]);

  useHotkeys("ctrl+k, cmd+k", (e: KeyboardEvent) => {
    // stop propagation to fix linux
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  });

  useKeyPressEvent("ArrowDown", () => {
    const next = descendants.nextEnabled(focusedIndex);
    if (next) {
      setFocusedIndex(next.index);
      next.node.focus();
      next.node.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });

  useKeyPressEvent("ArrowUp", () => {
    const prev = descendants.prevEnabled(focusedIndex);
    if (prev) {
      setFocusedIndex(prev.index);
      prev.node.focus();
      prev.node.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        close();
      }
    },
    [close]
  );

  const matchingProperties = properties
    .filter((p) => {
      const lSearchText = searchText.toLowerCase();

      const adressText = `${p.address1} ${p.address2} ${p.addressCity}`.toLowerCase();
      const addressMatches = adressText.includes(lSearchText);

      const customIdMatches = p?.customPropertyId?.toLowerCase().includes(lSearchText);

      return addressMatches || customIdMatches;
    })
    .slice(0, 8);

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <Portal>
          <MotionFlex
            onClick={close}
            variants={backdropVariants}
            initial="initial"
            animate="enter"
            exit="exit"
            position="fixed"
            inset={0}
            bg="whiteAlpha.800"
            _dark={{
              bg: "blackAlpha.800",
            }}
            zIndex="tooltip"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MotionVStack
              variants={commandPaletteVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              mt={{ base: 12, md: 24 }}
              mx={2}
              onClick={(e) => e.stopPropagation()}
              bg="white"
              _dark={{
                bg: "gray.900",
              }}
              shadow="2xl"
              maxW="3xl"
              maxH={{ base: "40vh", md: "md" }}
              overflow="hidden"
              w="full"
              rounded="md"
              borderWidth="1px"
              borderStyle="solid"
            >
              <>
                <Input
                  placeholder="Search for property to open"
                  ref={searchRef}
                  autoFocus
                  value={searchText}
                  onKeyDown={onKeyDown}
                  onChange={(e) => setSearchText(e.target.value)}
                  minHeight="40px" // Stop height changing while typing
                />
                <List overflow="auto" w="full" pb={2} px={2}>
                  <MenuDescendantsProvider value={descendants}>
                    {matchingProperties.map((property, index) => (
                      <PropertySearchMenuItem
                        key={index}
                        property={property}
                        onClick={() => {
                          onItemSelected(property);
                          close();
                        }}
                        focusedIndex={focusedIndex}
                      />
                    ))}
                  </MenuDescendantsProvider>
                </List>
              </>
            </MotionVStack>
          </MotionFlex>
        </Portal>
      )}
    </AnimatePresence>
  );
};
