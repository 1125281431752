import { useToast, UseToastOptions } from "@chakra-ui/react";

export const useTetherToast = () => {
  const toast = useToast();

  const errorToast = (options: UseToastOptions) => {
    toast({
      ...options,
      status: "error",
      isClosable: true,
      position: "top-right",
    });
  };

  const successToast = (options: UseToastOptions) => {
    toast({
      ...options,
      status: "success",
      isClosable: true,
      position: "top-right",
    });
  };

  return {
    errorToast,
    successToast,
  };
};
