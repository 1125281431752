import { Avatar, AvatarProps } from "@chakra-ui/avatar";
import { Stack } from "@chakra-ui/layout";
import React from "react";

interface UserIconProps extends AvatarProps {
  name: string;
  avatarUrl?: string;
  bg?: string;
}

export const UserIcon = ({ name, avatarUrl, bg = "black", ...others }: UserIconProps) => (
  <>
    <Stack direction="row">
      <Avatar textColor="white" size="sm" bg={bg} name={name} src={avatarUrl} {...others} />
    </Stack>
  </>
);
